import React, { Component, useState } from 'react';
import { Row, Col, Select, Card, Icon, Menu, Dropdown } from 'antd-min';
import { Link } from "react-router-dom";
import { GLUtil, GLGlobal, GLAction, alignPop } from 'gl-commonui';
import { PathConfig } from '@app/config/pathconfig';
import { GSAdminAction, DateHelper, GSSchoolAction, fmtMsg } from '@app/util/index';
import { GLTitle, GLUrlProps } from '@app/components/admin/gl-title';
import { RegionModel } from '@app/service/admin/regions/index';
import { GSAdminLocale, RewardPointsLocale, SchoolLocale } from '@app/locales/localeid';
import { GLIcon } from '@app/components';
import { IconFont } from '@app/util/consts';
import './regioncard.less'
import { CycleInfoModel } from '@app/states/region/region';
import { FormattedMessage } from "react-intl";

interface RegionCardProps {
    //regionId: string;
    isStudentVerificationVisible: boolean;
    regionModel?: RegionModel;
    regions?: RegionModel[];
    administrator?: string;
    coach?: string;
    currentRegionCycles?: CycleInfoModel[];
    onExportLicense?: (invoiceReferenceId, regionId) => void;
    extraData: {
        totalDigital: number;
        totalTextbook: number;
        totalLittleSEED: number;
    };
}

interface RegionCardState {
    invoiceReferenceId: string;
    menuVisible: boolean;
}

export class RegionCard extends Component<RegionCardProps, RegionCardState> {
    constructor(props) {
        super(props);
        this.state = {
            invoiceReferenceId: null,
            menuVisible: false
        }
        this.menuVisibleChange = this.menuVisibleChange.bind(this);
    }

    getOption(key, value, text) {
        return <Select.Option key={key} value={value}>{text}</Select.Option>
    }

    getRegionsHeader() {
        if (!this.props.regionModel.id || !this.props.regions) {
            return null;
        }
        return (
            <>
                <span className="region-card-body-col">{fmtMsg({ id: GSAdminLocale.RegionSchoolsRegion })}:&nbsp;</span>
                <span className="font-weight-normal">{this.props.regionModel.englishName}</span>
            </>
        )
    }

    onExportPeriodChange = (referenceId) => {
        this.setState({
            invoiceReferenceId: referenceId === "current" ? null : referenceId
        });
    }

    menuVisibleChange = () => {
        this.setState(state => ({
            menuVisible: !state.menuVisible
        }))
    }

    render() {
        const { currentRegionCycles, onExportLicense, isStudentVerificationVisible } = this.props;
        const { id, name, englishName, gsContentversion, lsContentversion, supportEmail, materialOrderEmail, billingDay } = this.props.regionModel;
        const { totalDigital, totalTextbook, totalLittleSEED } = this.props.extraData;
        const regionEditUrl: GLUrlProps = id ? { href: GLUtil.pathStringify(PathConfig.RegionEdit, { regionId: id }) } : null;
        const gsContentversionText = gsContentversion ? gsContentversion : '';
        const lsContentversionText = lsContentversion ? lsContentversion : '';
        const supportemailText = supportEmail ? supportEmail : '';
        const materialorderemailText = materialOrderEmail ? materialOrderEmail : '';
        const billingDayText = billingDay ? billingDay : '';
        const administrator = this.props.administrator === undefined ? '' : (this.props.administrator === null ? fmtMsg({ id: GSAdminLocale.SchoolCardNone }) : this.props.administrator);
        const adminUrl: GLUrlProps = id ? { href: GLUtil.pathStringify(PathConfig.RegionInvite, { regionId: id }) } : null;
        const currentBillingPeriod = currentRegionCycles.length && currentRegionCycles.find(crc => crc.isCurrentCycle);
        const currentBillingPeriodFormatted = currentBillingPeriod ? DateHelper.toLocalDate(currentBillingPeriod.startDate) + " ~ " + DateHelper.toLocalDate(currentBillingPeriod.endDate) : null;
        const pathOrders = id ? GLUtil.pathStringify(PathConfig.RegionOrders, { regionId: id }) : null;
        const pathTalkTimeSetting = id ? GLUtil.pathStringify(PathConfig.RegionTalkTimeSetting, { regionId: id }) : null;
        const pathTagManager = id ? GLUtil.pathStringify(PathConfig.RegionTagManager, { regionId: id }) : null;
        const pathTermManager = id ? GLUtil.pathStringify(PathConfig.RegionTermManager, { regionId: id }) : null;
        const pathPointsManager = id ? GLUtil.pathStringify(PathConfig.RegionRewardPoints, { regionId: id }) : null;
        const pathCreateSchool = id ? GLUtil.pathStringify(PathConfig.SchoolCreate, { regionId: id }) : null;
        const pathManageStudents = id ? GLUtil.pathStringify(PathConfig.ManageStudents, { regionId: id }) : null;
        const pathCoachList = id ? GLUtil.pathStringify(PathConfig.RegionCoaches, { regionId: id }) : null;
        const currentLocalized = fmtMsg({ id: SchoolLocale.CIMSChangeEventCurrent });
        const coach = this.props.coach === undefined ? '' : (this.props.coach === null ? fmtMsg({ id: GSAdminLocale.SchoolCardNone }) : this.props.coach);
        const coachUrl: GLUrlProps = id ? { href: GLUtil.pathStringify(PathConfig.RegionCoachInvite, { regionId: id }) } : null;
        const menus = (
            <Menu>
                {GLGlobal.isActionValid(GSAdminAction.EditRegion) && regionEditUrl &&
                    <Menu.Item>
                        <Link to={regionEditUrl.href}>
                            <GLIcon type="edit" />
                            <FormattedMessage id={GSAdminLocale.EditRegion} />
                        </Link>
                    </Menu.Item>}
                {GLGlobal.isActionValid(GSAdminAction.EditRegionAdminRegion) && adminUrl &&
                    <Menu.Item>
                        <Link to={adminUrl.href}>
                            <IconFont type="glscoach" />
                            <FormattedMessage id={GSAdminLocale.RegionAdministrator} />
                        </Link>
                    </Menu.Item>}
                {GLGlobal.isActionValid(GSAdminAction.CreateSchool) && pathCreateSchool &&
                    <Menu.Item>
                        <Link to={pathCreateSchool}>
                            <GLIcon type="plus-circle" />
                            <FormattedMessage id={GSAdminLocale.RegionSchoolsAdd} />
                        </Link>
                    </Menu.Item>}
                {GLGlobal.isActionValid(GSAdminAction.ManageStudents) && pathManageStudents && isStudentVerificationVisible &&
                    <Menu.Item>
                        <Link to={pathManageStudents}>
                            <GLIcon type="plus-circle" />
                            <FormattedMessage id={GSAdminLocale.RegionManageStudentsAction} />
                        </Link>
                    </Menu.Item>
                }
                {GLGlobal.isActionValid(GSSchoolAction.ListRegionOrders) && pathOrders &&
                    <Menu.Item>
                        <Link to={pathOrders}>
                            <GLIcon type="icon-Campus-Order-History" />
                            <FormattedMessage id={GSAdminLocale.RegionSchoolsOrders} />
                        </Link>
                    </Menu.Item>}
                {GLGlobal.isActionValid(GSAdminAction.ListRegionCoach) && pathCoachList &&
                    <Menu.Item>
                        <Link to={pathCoachList}>
                            <IconFont type="glscoach" />
                            <FormattedMessage id={GSAdminLocale.RegionCoaches} />
                        </Link>
                    </Menu.Item>}
                {GLGlobal.isActionValid(GSSchoolAction.ListRegionSchoolTalkTimeSettings) && pathTalkTimeSetting &&
                <Menu.Item>
                    <Link to={pathTalkTimeSetting}>
                        <GLIcon type="icon-region-teaching-setting" />
                        <FormattedMessage id={GSAdminLocale.RegionTalkTimeSetting} />
                    </Link>
                </Menu.Item>}
                {GLGlobal.isActionValid(GSSchoolAction.ListRegionSchoolTagView) && pathTagManager && (
                    <Menu.Item>
                        <Link to={pathTagManager}>
                            <GLIcon type="tags" />
                            <FormattedMessage id={GSAdminLocale.RegionTagManager} />
                        </Link>
                    </Menu.Item>
                )}

                {GLGlobal.isActionValid(GSAdminAction.ManageTerm) && pathTermManager && (
                    <Menu.Item>
                        <Link to={pathTermManager}>
                            <GLIcon type="icon-term-manager" />
                            <FormattedMessage id={GSAdminLocale.RegionTermManager} />
                        </Link>
                    </Menu.Item>
                )}
                {GLGlobal.isActionValid(GSAdminAction.ManagePointsView) && pathPointsManager && (
                    <Menu.Item>
                        <Link to={pathPointsManager}>
                            <Icon type="form" />
                            <FormattedMessage id={RewardPointsLocale.EntryText} />
                        </Link>
                    </Menu.Item>
                )}
            </Menu>
        );

        return (
            <div className='region-card'>
                <Card className='region-card region-card-body'>
                    <div className='region-card-body-row'>
                        <div className='region-card-body-col'>
                            <GLTitle
                                titleText={englishName}
                                titleValue=''
                                titleIsHeader={true}
                                titleComponent={this.getRegionsHeader()}
                                iconTitle={fmtMsg({ id: GSAdminLocale.RegionSchoolsEditRegion })}
                                iconAction={GSAdminAction.ViewRegion}
                                iconUrl={regionEditUrl}
                                titleMargin={true}
                            />
                        </div>
                    </div>
                    <p className='region-card-body-row'>
                        <span className='region-card-body-col'><span className="bold">{fmtMsg({ id: GSAdminLocale.RegionSchoolsGsVersion })}</span>: {gsContentversionText}</span>
                        <span className='region-card-body-col'><span className="bold">{fmtMsg({ id: GSAdminLocale.RegionSchoolsLsVersion })}</span>: {lsContentversionText}</span>
                    </p>
                    <div className='region-card-body-row'>
                        <div className='region-card-body-col'>
                            <div className="support-email">
                                <div className="bold">{fmtMsg({ id: GSAdminLocale.RegionSchoolsSupportEmail })}: </div>
                                <div>{supportemailText}</div>
                            </div>
                        </div>
                        <div className='region-card-body-col'>
                            <div className="material-order-email">
                                <div className="bold">{fmtMsg({ id: GSAdminLocale.RegionSchoolsMaterialEmail })}: </div>
                                <div>{materialorderemailText}</div>
                            </div>
                        </div>
                    </div>
                    <div className='region-card-body-row'>
                        <div className='region-card-body-col'>
                            <GLTitle
                                titleText={fmtMsg({ id: GSAdminLocale.RegionSchoolsAdmin }) + ":"}
                                titleValue={administrator}
                                iconTitle={fmtMsg({ id: GSAdminLocale.RegionSchoolsAdmins })}
                                iconAction={GSAdminAction.ListRegionAdmin}
                                iconUrl={adminUrl}
                            /></div>
                        <div className='region-card-body-col'>
                            <GLTitle
                                titleText={fmtMsg({ id: GSAdminLocale.RegionCoach }) + ":"}
                                titleValue={coach}
                                iconTitle={fmtMsg({ id: GSAdminLocale.RegionCoaches })}
                                iconAction={GSAdminAction.ListRegionCoach}
                                iconUrl={coachUrl}
                            />
                        </div>
                    </div>
                    <div className='region-card-body-row'>
                        <div className='region-card-body-col'>
                            <span className="bold">{fmtMsg({ id: GSAdminLocale.RegionSchoolPeriod })}</span>: {currentBillingPeriodFormatted}
                        </div>
                        <div className='region-card-body-col'>
                            <span className="bold">{fmtMsg({ id: GSAdminLocale.RegionSchoolsBillingDay })}
                            </span>: {billingDayText}
                        </div>
                    </div>
                </Card>
                <div className="right">
                    <div className='region-card-body-col'>
                        <div className='manage-region-action'>
                            <Dropdown overlay={menus} trigger={['click']} onVisibleChange={this.menuVisibleChange} getPopupContainer={(triggerNode) => triggerNode.parentElement} {...alignPop()}>
                                <span>
                                    <Icon type="unordered-list" />
                                    {fmtMsg({ id: GSAdminLocale.RegionManageAction })}
                                    {this.state.menuVisible ? <Icon type="up" className="anticon-arrow" /> : <Icon type="down" className="anticon-arrow" />}
                                </span>
                            </Dropdown>
                        </div>
                    </div>
                    {
                        GLGlobal.isActionValid(GSAdminAction.ListRegionLicense) &&
                        <Row className="license-totals">
                            <Col className="title region-card-body-row"><span>{fmtMsg({ id: GSAdminLocale.RegionSchoolsTotals })} </span></Col>
                            <Col className="content region-card-body-row">
                                <div className="content-license-export">
                                    <GLAction action={GSAdminAction.ExportRegionLicense}>
                                        <Col className="expo-cy">
                                            <Select className="expo-cy__select" placeholder={fmtMsg({ id: GSAdminLocale.RegionSchoolsExportCurrent })} defaultValue={currentLocalized} onChange={this.onExportPeriodChange} key={currentLocalized}>
                                                {currentRegionCycles.map((c, index) => {
                                                    return <Select.Option value={c.invoiceReferenceId ? c.invoiceReferenceId : "current"} key={index}>
                                                        {c.isCurrentCycle
                                                            ? currentLocalized
                                                            : DateHelper.formatDate2Local(c.invoiceGenerationDate)}
                                                    </Select.Option>
                                                })}
                                            </Select>
                                        </Col>
                                        <Col className="expo-cy__export expo-cy__export_licenses">
                                            <div onClick={(e) => onExportLicense(this.state.invoiceReferenceId, id)}>
                                                <Icon type="download" /> {" "}
                                                {fmtMsg(GSAdminLocale.RegionSchoolsExportLicenses)}
                                            </div>
                                        </Col>
                                    </GLAction>
                                </div>
                                <div className="content-license-data">
                                    <Col className="subtitle region-card-body-row"><span className="bold">{fmtMsg({ id: GSAdminLocale.RegionSchoolsDigital })}:</span>{totalDigital}</Col>
                                    <Col className="subtitle region-card-body-row"><span className="bold">{fmtMsg({ id: GSAdminLocale.RegionSchoolsTextbook })}:</span>{totalTextbook}</Col>
                                    <Col className="subtitle region-card-body-row"><span className="bold">{fmtMsg({ id: GSAdminLocale.RegionSchoolsLittleSEED })}:</span>{totalLittleSEED}</Col>
                                </div>
                            </Col>
                        </Row>
                    }
                </div>
            </div>
        );
    }
}
