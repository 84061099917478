import { ColumnLink, WijmoGrid } from "@app/components/";
import { Tagging } from "@app/components/tagging/tagging";
import { PathConfig } from "@app/config/pathconfig";
import { DashboardLocale, GSAdminLocale } from "@app/locales/localeid";
import { RegionModel } from "@app/service/admin/regions/model";
import { ISchoolService, SchoolModelPropNames } from "@app/service/schools/index";
import { ITagManagerService, TagEntity, TagListModel } from "@app/service/tag-manager";
import { fmtMsg, GSAdminAction, lazyInject, ListStates, TYPES } from "@app/util/index";
import { Icon } from "antd";
import { GLGlobal, GLUtil, withRouter } from "gl-commonui";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import "./schools.less";

const { Grid, Column } = WijmoGrid;

interface SchoolsComponentProps extends Partial<RouteComponentProps> {
    currentRegion?: RegionModel;
    changeListState: (s) => void;
    getUrlQuery: (s) => any;
    callbackParent: (s) => void;
    // setCurrentCycle: (s: CycleInfoModel) => void;
    listState: ListStates;
    regionId: string;
}

interface SchoolsComponentStates {}

@withRouter
export class SchoolsComponent extends Component<SchoolsComponentProps, SchoolsComponentStates> {
    @lazyInject(TYPES.ISchoolService)
    service: ISchoolService;
    @lazyInject(TYPES.ITagManagerService)
    tagManagerService: ITagManagerService;
    gridRef?: any = React.createRef();
    constructor(props, context) {
        super(props, context);
        this.state = {};
    }

    private getSchools(params: Object): Promise<any> {
        return new Promise((resolve, reject) => {
            const schoolServiceClass = this.service.getItemsBy.bind(this.service);
            schoolServiceClass(params)
                .then((res) => {
                    const schools = res.data.schools as any[];
                    const schoolIds: string[] = res.data.schools.map((school) => school.id);
                    this.tagManagerService
                        .getEntityTags(schoolIds)
                        .then((entityTags) => {
                            schools.forEach((school) => {
                                const schoolTags = entityTags.find((entityTag) => entityTag.entityId === school.id);
                                school.tags = schoolTags ? schoolTags.tags : [];
                            });
                            resolve(res);
                        })
                        .catch(reject);
                    return schools;
                })
                .catch(reject);
        });
    }

    private reloadGrid = () => {
        if (this.gridRef && this.gridRef.reload) {
            this.gridRef.reload();
        }
    }

    render() {
        const regionId = this.props.currentRegion ? this.props.currentRegion.id : null;
        const hasTagViewPermission = GLGlobal.isActionValid(GSAdminAction.SchoolTagsView);
        const hasTagManagerPermission = GLGlobal.isActionValid(GSAdminAction.SchoolTagsManage);
        return (
            <>
                <Grid
                    ref={(ref) => this.gridRef = ref}
                    className="schooltable"
                    serviceFunction={this.getSchools.bind(this)}
                    serviceParams={{ regionId: this.props.regionId }}
                    serviceFormatData={(data) => {
                        this.props.callbackParent(data.extraData);
                        return this.service.formatSchoolList(data.data.schools);
                    }}
                    search={true}
                    searchOptions={{
                        label: fmtMsg({ id: DashboardLocale.SearchPlaceHolderSchool }),
                    }}
                    title={fmtMsg({ id: GSAdminLocale.AvatarMenuSchools })}
                    listStateShow={true}
                    listStateOptions={{ futureCount: null }}
                    allowExport={!this.props.currentRegion.disabled}
                    exportOptions={{
                        buttonLocaleId: GSAdminLocale.RegionSchoolsExportSchools,
                        buttonIcon: <Icon className="export-icon" type="download"/>,
                        formatData: (data) => this.service.formatSchoolList(data.data.schools, true),
                        filename: this.props.currentRegion && this.props.currentRegion.name,
                        // includeColumns: [
                        //     { header: fmtMsg(GSAdminLocale.SchoolModelDigital), binding: SchoolModelPropNames.digitalLicense, width: 120 },
                        //     { header: fmtMsg(GSAdminLocale.SchoolModelTextbook), binding: SchoolModelPropNames.textbookLicense, width: 120 },
                        //     { header: fmtMsg(GSAdminLocale.SchoolModelLittleSEED), binding: SchoolModelPropNames.littleSeedLicense, width: 120 },
                        // ],
                        noDataMessage: fmtMsg({ id: GSAdminLocale.RegionSchoolsNoSchoolExport }),
                        successMessage: fmtMsg({ id: GSAdminLocale.RegionSchoolsExportSchoolSuccess }),
                    }}
                    defaultSort={{ column: SchoolModelPropNames.name, ascending: true }}
                >
                    <Column
                        binding={SchoolModelPropNames.name}
                        header={fmtMsg({ id: GSAdminLocale.SchoolModelName })}
                        render={(value, record) => {
                            const url = regionId ? GLUtil.pathStringify(PathConfig.Schools, { regionId, schoolId: record.id }) : null;
                            return (
                                <ColumnLink history={this.props.history} url={url}>
                                    {value}
                                </ColumnLink>
                            );
                        }}
                    />
                    <Column binding={SchoolModelPropNames.gsNumber} header={fmtMsg({ id: GSAdminLocale.SchoolModelCode })} />
                    <Column
                        binding={SchoolModelPropNames.subscriptionType4Sort}
                        header={fmtMsg({ id: GSAdminLocale.SchoolModelSubscriptionType })}
                        sortMemberPath={SchoolModelPropNames.subscriptionType}
                    />
                    <Column
                        binding={SchoolModelPropNames.trainerName}
                        header={fmtMsg({ id: GSAdminLocale.SchoolModelTrainer })}
                        render={(value, record) => {
                            if (record.hasMultipleTrainer) {
                                const url = regionId ? GLUtil.pathStringify(PathConfig.SchoolTrainers, { regionId, schoolId: record.id }) : null;
                                return (
                                    <>
                                        <span>{value}</span>
                                        <ColumnLink history={this.props.history} title={fmtMsg({ id: GSAdminLocale.HomeShowMore })} url={url}>
                                            <span style={{ margin: "3px" }}>+</span>
                                        </ColumnLink>
                                    </>
                                );
                            } else {
                                return value;
                            }
                        }}
                    />
                    <Column binding={SchoolModelPropNames.grapeSeedLicense} header={fmtMsg({ id: GSAdminLocale.SchoolModelGSCount })} sortMemberPath="licenses.grapeSeedLicense" />
                    <Column binding={SchoolModelPropNames.littleSeedLicense} header={fmtMsg({ id: GSAdminLocale.SchoolModelLSCount })} sortMemberPath="licenses.littleSeedLicense" />
                    <Column
                        binding={SchoolModelPropNames.pendingChangesCount}
                        header={fmtMsg({ id: GSAdminLocale.SchoolModelChanges })}
                        render={(value, record) => {
                            if (GLGlobal.isActionValid(GSAdminAction.ListSchoolChangeHistory)) {
                                const pathChanges = regionId
                                    ? GLUtil.pathStringify(PathConfig.SchoolChangeHistory, { regionId, schoolId: record.id })
                                    : null;
                                return (
                                    <ColumnLink history={this.props.history} url={pathChanges} underline>
                                        {value}
                                    </ColumnLink>
                                );
                            } else {
                                return value;
                            }
                        }}
                    />
                    <Column binding={SchoolModelPropNames.prepComplete} header={fmtMsg({ id: GSAdminLocale.SchoolModelPrepComplete })} />
                    {hasTagViewPermission && (
                        <Column
                            binding={SchoolModelPropNames.tagsJoined}
                            header={fmtMsg({ id: GSAdminLocale.SchoolModelTags })}
                            allowSorting={false}
                            minWidth={370}
                            maxWidth={370}
                            wordWrap={true}
                            render={(value: string, record, context) => {
                                if (!regionId) {
                                    return null;
                                }
                                return (
                                    <Tagging
                                        selectedTags={record.tags}
                                        entityId={record.id}
                                        regionId={regionId}
                                        entity={TagEntity.School}
                                        wijmoGridContext={context}
                                        isViewOnly={!hasTagManagerPermission}
                                        onSave={this.reloadGrid}
                                    />
                                );
                            }}
                        />
                    )}
                </Grid>
            </>
        );
    }
}
