import React, { Component, AnchorHTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import { LocationDescriptor } from 'history';
import { Icon } from 'antd-min';
import classNames from 'classnames';
import { GLAction } from 'gl-commonui';
import './gl-title.less';

export interface GLUrlProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
    className?: string
    isExternal?: boolean
}

export interface GLTitleProps {
    titleText: string
    titleValue: string
    titleIsHeader?: boolean
    titleComponent?: JSX.Element
    titleUrl?: GLUrlProps
    iconUrl?: GLUrlProps
    iconAction?: string
    iconTitle?: string
    titleMargin?: boolean
    extendIconComponent?: JSX.Element
}

export const GLTitle = (props: GLTitleProps) => {
    const titleClass = {
        'gl-title': true,
        'gl-title-margin': props.titleMargin
    }
    return (
        <div className={classNames(titleClass)}>
            {props.titleUrl && <GLLink {...props.titleUrl}><Title {...props} /></GLLink>}
            {!props.titleUrl && <Title {...props} />}
            { props.iconUrl &&
                <GLAction action={props.iconAction}>
                    <GLLink {...props.iconUrl}>
                        <Icon type='edit' title={props.iconTitle} />
                    </GLLink>
                </GLAction>
            }
            {props.extendIconComponent}
        </div>
    )    
}

const Title = (props: GLTitleProps) => {
    if (props.titleIsHeader) {
        return <TitleHeader {...props} />
    }
    else {
        return <TitleText {...props} />
    }
}

const TitleText = (props: GLTitleProps) => {
    return (
        <p><span>{props.titleText}</span>{props.titleValue}</p>
    )
}

const TitleHeader = (props: GLTitleProps) => {
    if (props.titleComponent) {
        return props.titleComponent
    }
    else {
        return (
            <React.Fragment>
                <h3>{props.titleText}</h3>
                <span>{props.titleValue}</span>
            </React.Fragment>
        )    
    }
}

const GLLink = ({isExternal, children, ...rest}: GLUrlProps) => {
    if (isExternal) {
        return <a {...rest}> {children} </a>
    }
    else {
        return <Link to={rest.href} {...rest}> {children} </Link>
    }
}