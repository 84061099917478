import React, { Component } from 'react'
import { RouteComponentProps } from "react-router-dom";
import { connect, withRouter, GLUtil, InvitationType, ResourceType, GLGlobal, PaginationParams, maskThrottle, unmaskThrottle, PathConfig as CommonPath, MessageHelper, NotificationType, Role } from 'gl-commonui';
import { PathConfig } from '@app/config/pathconfig';
import { lazyInject, TYPES, GSAdminAction, SchoolHelper, ListStates, ContextHelper, SessionStorageKeys, BlobHelper, fmtMsg, isStudentVerificationAllowed } from '@app/util/index';
import { ISchoolService } from '@app/service/schools';
import { IRegionService, RegionModel } from '@app/service/admin/regions/index';
import { IContentVersionService, ContentVersionModel } from '@app/service/admin/contentversion/index';
import { IResourceService } from '@app/service/resources/index';
import { IUserService } from '@app/service/users/index';
import { RegionCard } from './components/regioncard';
import { SchoolsComponent } from '../schools/components/schools';
import { ListStatesStore, MainTitle, Container } from '@app/components';
import { GSAdminLocale } from '@app/locales/localeid';
import { CycleInfoModel } from '@app/states/region/region';
import { StateType } from "@app/states";

interface RegionPageProps extends RouteComponentProps<any> {
}
interface RegionPageStates {
    region?: RegionModel
    regions?: RegionModel[]
    regionAdmin?: string
    regionCoach?: string
    listState: ListStates
    extraData: {
        totalDigital: number,
        totalTextbook: number,
        totalLittleSEED: number
    }
    currentRegionCycles?: CycleInfoModel[],
    isStudentVerificationVisible: boolean
}
@connect(
    ({ link }: StateType) => {
        return {
            link
        };
    },
    {
    }
)
@withRouter
export class RegionPage extends Component<RegionPageProps, RegionPageStates> {
    @lazyInject(TYPES.IRegionService)
    regionService: IRegionService
    @lazyInject(TYPES.IContentVersionService)
    contentVersionService: IContentVersionService
    @lazyInject(TYPES.IResourceService)
    resourceService: IResourceService
    @lazyInject(TYPES.IUserService)
    userService: IUserService
    @lazyInject(TYPES.ISchoolService)
    schoolService: ISchoolService
    listStateStore: ListStatesStore
    currentGeneratedCycle: CycleInfoModel
    constructor(props, context) {
        super(props, context);
        this.listStateStore = new ListStatesStore("schools-list-states", this.props.match.params.regionId);
        this.state = {
            listState: this.listStateStore.getState(),
            region: {},
            regions: [],
            extraData: {
                totalDigital: 0,
                totalTextbook: 0,
                totalLittleSEED: 0
            },
            regionAdmin: undefined,
            regionCoach: undefined,
            currentRegionCycles: [],
            isStudentVerificationVisible: false
        };
    }

    componentWillReceiveProps(nextProps: any, nextContext: any) {
        const { regionId: preRegionId } = this.props.match.params;
        const { regionId: nextRegionId } = nextProps.match.params;
        if (preRegionId != nextRegionId || this.props.location.pathname != this.props.history.location.pathname) {
            this.getRegionInfo(nextRegionId);
            this.listStateStore.setStateKey(nextRegionId);
        }
    }

    componentDidMount() {
        const regionId = GLUtil.pathParse(PathConfig.Region).regionId;
        this.getRegionInfo(regionId);
        this.isStudentVerificationAllowed(regionId);
    }
    changeListState(state) {
        this.setState({ listState: state });
        this.listStateStore.setState(state);
    }
    getUrlQuery(state) {
        return this.listStateStore.getUrlQuery(state);
    }
    async isStudentVerificationAllowed(regionId) {
        var isAllowed = await isStudentVerificationAllowed(regionId);
        this.setState({ isStudentVerificationVisible: isAllowed });

    }
    getRegionInfo(regionId) {
        maskThrottle();
        this.regionService.getRegionById({ id: regionId }).then((region) => {
            let actions = [];
            actions.push(this.contentVersionService.get<ContentVersionModel[]>());
            let params = {
                resourceId: regionId,
                resourceType: InvitationType.regionAdmin
            };
            actions.push(this.resourceService.getResourceUserIds(params).then((userIds) => {
                if (userIds.length == 0) {
                    return new Promise((resolve, reject) => { resolve({ data: [] }) });
                }
                else {
                    return this.userService.getItemsBy({ ids: userIds });
                }
            }));

            actions.push(this.regionService.getItemsBy({ includeTraining: true }));
            actions.push(this.regionService.getCycleInfo({ regionId }));
            let coachParams = {
                resourceId: regionId,
                resourceType: ResourceType.Region,
                targetRole: Role.Trainer
            };
            actions.push(this.resourceService.getUserIds(coachParams).then((userIds) => {
                if (userIds.length == 0) {
                    return new Promise((resolve, reject) => { resolve({ data: [] }) });
                }
                else {
                    return this.userService.getItemsBy({ ids: userIds });
                }
            }));
            Promise.all(actions).then((res) => {
                const [contentVersions, admins, regions, cycles, coaches] = res;
                const selectedRegion = regions.data && regions.data.find(region => region.id == regionId);
                if (!selectedRegion) {
                    this.props.history.push({ pathname: CommonPath.AccessDenied });
                }
                const regionAdmin = admins.data.length > 0 ? admins.data[0].name : null;
                const regionCoach = coaches.data.length > 0 ? coaches.data[0].name : null;
                const gsContentversionText = contentVersions.find((version) => version.id == region.gsVersionGroupId);
                if (gsContentversionText) { region.gsContentversion = gsContentversionText.name; }
                const lsContentversionText = contentVersions.find((version) => version.id == region.lsVersionGroupId);
                if (lsContentversionText) { region.lsContentversion = lsContentversionText.name; }
                this.setState({
                    region: region,
                    regions: regions.data,
                    regionAdmin: regionAdmin,
                    regionCoach: regionCoach,
                    currentRegionCycles: cycles
                });
                this.currentGeneratedCycle = cycles.find(i => i.isCurrentInvoiceGenerated);
                SchoolHelper.saveDefaultRegion(region);
                const pagination = new PaginationParams(1, 20)
                pagination.current = 1;
                SchoolHelper.saveSchoolListPagination(pagination);
                ContextHelper.setSessionPagination(SessionStorageKeys.LocalProductsPagination, pagination);
                unmaskThrottle();
            }).catch((error) => {
                console.log(error);
            });
        }).catch((error) => {
            console.log(error);
        });
    }

    callbackParent(data) {
        this.setState({ extraData: data });
    }

    onExportLicense(invoiceReferenceId, regionId) {
        // Call api to export data
        const params = { invoiceReferenceId, regionId, isCurrentExists: this.currentGeneratedCycle != undefined };
        maskThrottle();
        this.regionService.exportBillingHistory(params).then(response => {
            let message = fmtMsg({ id: GSAdminLocale.RegionSchoolsNoLicenseExport });
            if (BlobHelper.saveFile(response)) {
                message = fmtMsg({ id: GSAdminLocale.RegionSchoolsExportSuccess });
            }
            MessageHelper.Message(NotificationType.Success, message);
            unmaskThrottle();
        }).catch((error) => {
            unmaskThrottle();
        });
    }

    render() {
        const canListSchool = GLGlobal.isActionValid(GSAdminAction.ListSchool);
        return (
            <Container fullWidth fullHeight className="region-page">
                <MainTitle plain={this.state.region.englishName} className="region-page__title" />
                <RegionCard
                    isStudentVerificationVisible={this.state.isStudentVerificationVisible}
                    regionModel={this.state.region}
                    regions={this.state.regions}
                    administrator={this.state.regionAdmin}
                    coach={this.state.regionCoach}
                    extraData={this.state.extraData}
                    currentRegionCycles={this.state.currentRegionCycles}
                    onExportLicense={this.onExportLicense.bind(this)}
                />
                {canListSchool &&
                    <SchoolsComponent
                        currentRegion={this.state.region}
                        changeListState={this.changeListState.bind(this)}
                        getUrlQuery={this.getUrlQuery.bind(this)}
                        listState={this.state.listState}
                        callbackParent={this.callbackParent.bind(this)}
                        regionId={this.props.match.params.regionId}
                    />
                }
            </Container>
        );
    }
}